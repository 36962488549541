import Doleman from './img/doleman.png';
import Steadfast from './img/steadfast.png';
import Employee from './img/employeeTracker.png';
import MoviesDB from './img/moviesdb.png';
import TopGearMusic from './img/topgearmusic.png';
import GithubSearch from './img/githubSearchUser.JPG';
import WorkoutApp from './img/fitnessTracker.png';
import StockPhotos from './img/stockPhotos.png';
import BudgetTraker from './img/budgetTracker.png';
import EatTheBurger from './img/eatTheBurger.png';
import EmployeeTracker from './img/employeeTracker.gif';
import ReadMeGenerator from './img/readMeGenerator.gif';
import GithubFinder from './img/githubFinder.png';
export const projects = [
  {
    id: 1,
    img: Doleman,
    link: 'https://dolemancontactmanager.herokuapp.com/',
    code: 'https://github.com/dangulo4/doleman',
    title: 'Doleman',
    description: 'Doleman is a React web application performing CRUD functions for contact management.',
  },
  {
    id: 2,
    img: Steadfast,
    link: 'https://mysterious-castle-55265.herokuapp.com/login',
    code: 'https://github.com/dangulo4/api_Steadfast',
    title: 'Steadfast',
    description:
      'Steadfast is a React web application that enables companies to easily find contacts at prospective domains (powered by Hunter.io), ability to integrate contacts with a user interface through a seamless user experience.',
  },
  {
    id: 3,
    img: Employee,
    link: 'https://employee-management-localstorage.netlify.app/',
    code: 'https://employee-management-localstorage.netlify.app/',
    // code: 'https://github.com/dangulo4/employees-appv2',
    title: 'Employee Management Directory',
    description:
      'Employee Directory is a React web application using random.me api to extract and filter fictious employees based on full name or city.',
  },
  {
    id: 4,
    img: MoviesDB,
    link: 'https://imdb-movie-db.netlify.app/',
    code: 'https://imdb-movie-db.netlify.app/',
    // code: 'https://github.com/dangulo4/movie-db',
    title: 'MovieDB',
    description:
      'Build on React, search movies using imbd api where you can learn movie facts when clicking on selected movie.',
  },
  {
    id: 5,
    img: TopGearMusic,
    link: 'https://mock-topgearshop.netlify.app/amp',
    code: 'https://mock-topgearshop.netlify.app/amp',
    // code: 'https://github.com/dangulo4/mock-topgearshop',
    title: 'Top Gear Music',
    description: 'This application uses express backend and save and retrieve note data from a JSON file.',
  },

  {
    id: 6,
    img: StockPhotos,
    link: 'https://stockphotos-app.netlify.app/',
    code: 'https://stockphotos-app.netlify.app/',
    // code: 'https://github.com/dangulo4/stock-photos',
    title: 'Stock Photos',
    description: 'React Photo app uses api unpsplash and loads new photos as you scroll down.',
  },
  {
    id: 7,
    img: GithubSearch,
    link: 'https://github-user-react.netlify.app',
    code: 'https://github.com/dangulo4/github-search',
    title: 'Github Search User',
    description:
      'Search for github users using github api. The single page app uses react router with Auth0  to add authentication and authorization services.',
  },
  {
    id: 8,
    img: WorkoutApp,
    link: 'https://workout-me.netlify.app',
    code: 'https://workout-me.netlify.app',
    // code: 'https://github.com/dangulo4/workout-tracker',
    title: 'Fitness Tracker Dashboard',
    description: 'Ability to track the name, type, weight, sets, reps, and duration of exercise.',
  },
  {
    id: 9,
    img: BudgetTraker,
    link: 'https://thawing-sea-70484.herokuapp.com/',
    code: 'https://github.com/dangulo4/pwa-budget-tracker',
    title: 'PWA Budget Tracker',
    description: 'Budget Tracker application to allow for offline access and functionality.',
  },
  {
    id: 10,
    img: EatTheBurger,
    link: 'https://hidden-earth-57033.herokuapp.com/',
    code: 'https://github.com/dangulo4/eat-da-burger',
    title: 'Eat Da Burger',
    description: 'Following the MVC design pattern; Node and MySQL to query data,Handlebars to generate your HTML.',
  },
  {
    id: 11,
    img: EmployeeTracker,
    link: '',
    code: 'https://github.com/dangulo4/employee-tracker',
    title: 'Employee Management System',
    description: 'Content Management Systemssolution for managing a employees using node, inquirer, and MySQL.',
  },
  {
    id: 12,
    img: ReadMeGenerator,
    link: '',
    code: 'https://github.com/dangulo4/ReadMe-Generator',
    title: 'ReadMe Generator',
    description: 'Following the MVC design pattern; Node and MySQL to query data,Handlebars to generate your HTML.',
  },
  {
    id: 13,
    img: GithubFinder,
    link: 'githubfinder-user.netlify.app',
    code: 'https://githubfinder-user.netlify.app/',
    // code: 'https://github.com/dangulo4/github-finder',
    title: 'GitHub Finder',
    description: 'Search for user on github api. This app uses context to pass props and using React for front end.',
  },
];
